import React, { Component } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import { Heading } from '@mc/wink';
import stylesheet from './NoData.less';

/**
 * Generic NoData component
 * TODO: Validation of imgSrc
 * TODO: Defaults for imgSrc and imgAlt?
 */
export default class NoData extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
      .isRequired,
    imgSrc: PropTypes.string.isRequired,
  };

  render() {
    const { children, className, imgSrc, description } = this.props;

    const classList = cx(stylesheet.container, className);

    return (
      <section className={classList}>
        <img
          className={stylesheet.illustration}
          src={imgSrc}
          alt=""
          role="presentation"
        />
        {mcdsFlagCheck('xp_project_runway_design_foundation') ? (
          <Heading
            level={2}
            appearance="heading-3"
            className={stylesheet.header}
          >
            {description}
          </Heading>
        ) : (
          <h2 className={stylesheet.header}>{description}</h2>
        )}
        {children}
      </section>
    );
  }
}
